@keyframes pop {
  from{
    transform: scale(0);

  }
  to{
    transform: scale(1);
  }
}
@keyframes pop1 {
  from{
    transform: scale(0);

  }
  to{
    transform: scale(1);
  }
}
@keyframes pop4 {
  from{
    transform: scale(0);

  }
  to{
    transform: scale(1);
  }
}
@keyframes popintop {
  from{
    transform: translateY(-100%);

  }
  to{
    transform: translateY(0%);
  }
}
@keyframes popinbottom {
  from{
    transform: translateY(100%);

  }
  to{
    transform: translateY(-0);
  }
}


.App {
  display: flex;
  flex-direction: row;
  width:100vw;
  height:100%;
  overflow: hidden;
  position: fixed;

}
img{
  width:60%;
  padding:2vw;
  object-fit: contain;
 

}
.confetti-img{
  padding:0;
  height:100%;
  margin:0;
  width:100%;
}
/* text */
.vs-text{
  font-size: bold;
  font-family: sans-serif;
  font-size: 7vw;
  margin:0;
  z-index: 2;
}
p{
  font-size: 0.9rem;
  color:white;
  margin:0;
  margin:0.5rem;
  margin-bottom: 0.2rem;
}
.center-text{
  text-align: center;
}
.bold-text{
  font-weight: bold;
  font-size: 1rem;
}
.next-comp-text{
  margin:0;
}
.row{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.theme-row{
  margin-top:0.5rem;
  margin-left:0.5rem;
}
/* views */
.game-view{
display: flex;
flex-direction: column;
width:75%;
height:100%;
justify-content: space-evenly;
align-items: center;
position: relative;
overflow: hidden;
transition: 0.5s;
}
.background-view{
  width:130%;
  height:100%;
  transform: rotate(-45deg) scale(1.5);
  position: absolute;
  z-index: 0;
  /* background-image: radial-gradient(#F6F6F6, #4A91C4); */
}
.radial-view{
  position: absolute;
  height: 100vw;
  width: 100vw;
  transform: scale(1);
  background-image: radial-gradient(#F6F6F6, #4A91C4);
  opacity: 0.7;
}
/* comps */
.side-bar{
  position: relative;
  width:25%;
  padding:1.5vw;
  transition:0.5s;
}
.top-sidebar{
  height:93%;
  overflow-y: scroll;

}
.bottom-sidebar{
  height:7%;
}
.side-bar-comp{
  margin-bottom: 4vh;
}
.choice-comp{
  position: relative;
  display: flex;
  flex-direction: row;
  padding:1rem;
  width: calc(57% - (780px - 100vh));
  min-width: 20rem;
  max-width: 100rem;
  margin-left:auto;
  margin-right:auto;
  justify-content: space-between;
  border-radius: 0.5rem;
  box-shadow: 0 0 20px rgba(0,0,0,0.3);
  cursor: pointer;
  z-index: 1;
}
.background-confetti{
  width:100%;
  height:100%;
  position: absolute;
  justify-content: center;
  display: flex;
  align-items: center;
}
.choice{
  width:31%;
  
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  /* aspect-ratio: 1 / 1; */
  
}
.background-row{
  width:100%;
  height:16%
}
.input-holder{
  display: flex;
  padding:0.7rem;
  width:50%;
  height:3rem;
  z-index: 2;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
   border-radius: 0.5rem;
}

.input-comp{
  width:100%;
  height:100%;
  background-color: white;
  font-weight: bold;
  font-size: 1rem;
  margin: 0;
  border:0;
  padding:0;
  border-radius: 0.2rem;
  
}
.next-op-comp{
  z-index: 2;
}
.opponent{
  padding:0.4rem;
  border-radius: 0.2rem;
  margin-right: 0.2rem;
  font-weight: bold;
}
.next-opponent{
  padding:1vw;
  margin:1vw;
  border-radius: 0.2rem;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  cursor: pointer;
}
.next-opponent:hover{
  opacity: 0.7;
}

.rang-comp{
  padding:0.2rem;
  border-radius: 0.2rem;
  width:7%;
  display:flex;
  align-items: center;
  justify-content: center;
}
.opponent-name{
  position: absolute;
  display: flex;
  width: calc(100% - 2rem);
  justify-content: center;
  align-items: center;
}
.opponent-comp-text{
  text-align: center;
  min-width:39%;
  padding:0.4rem;
  border-radius: 0.2rem;
  font-size: 0.8rem;
}
.opponent-top{
  top:-1.8rem;
}
.opponent-bottom{
  bottom:-1.8rem;
}
.sidebar-activator{
  display: none;
}
.win-row-comp{
  position: absolute;
  width:calc(100% - 2rem);
  left:-0.3rem;
  height: 22%;
  padding:0.4rem;
  top:-28%;
  display: flex;
 
}
/* buttons */
.color-button{
 height:2rem;
 width:2rem;
 border-radius: 0.2rem;
 box-shadow: 0 0 0.5rem rgba(0,0,0,0.3);
 border-style: solid;
 border-width: 0.1rem;
 border-color: transparent;
 margin-right: 3%;
 cursor: pointer;
}
.active-button{
  border-color: white;
  border-width: 0.1rem;
}
.add-button{
  height:100%;
  aspect-ratio: 1/1;
  color:white;
  font-weight: bold;
  font-size: 1.5rem;
  border-style: solid;
  border-color: white;
  border-radius: 0.2rem;
  cursor: pointer;
  transition: 0.5s;
}
.add-button:hover{
  transform: scale(1.05);
}
.close-button{
  position: absolute;
  width:30%;
  height: 2rem;
  top:-3rem;
  right:0.5rem;
  color:white;
  border-color: white;
  border-style: solid;
  border-radius: 0.2rem;
  font-weight: bold;
  cursor: pointer;
  
}

.close-button:active{
  opacity: 0.8;
}
.share-button{
  
  bottom:1vh;
  height:100%;
  width: 100%;
  border-style: solid;
  border-color: transparent;
  cursor: pointer;
  color:white;
  font-size: 1vw;
  font-weight: bold;


}
.share-button:hover{
  opacity: 0.5;
}
.win-button{
  border-style: solid;
  margin:0;
  border-color: transparent;
  border-radius: 0.2rem;
  cursor: pointer;
  width:12%;
  box-shadow: 0 0 5px rgba(0,0,0,0.3);
}
.win-button:hover{
  opacity: 0.8;
}

/* themes */
/* bluetheme */
.blue-theme{
  background-color: #4A91C4;

}
.dark-blue{
  background-color: #306b95;
}
.blue-text{
  color:#4A91C4
}

.light-blue{
  background-color:#50a3de
}
.dark-dark-blue{
  background-color: #05243a;
}
/* purple-thme */
.purple-theme{
  background-color: #8e3dbd;
}
.purple-text{
 color: #8e3dbd;
}
.dark-purple{
  background-color: #4a0c6e
}
.light-purple{
  background-color:#b536ff
}
.dark-dark-purple{
  background-color: #230335;
}

/* orange-theme */
.orange-theme{
  background-color: #b24f0d;
}
.orange-text{
color: #b24f0d;
}
.dark-orange{
  background-color: #753408;
}
.light-orange{
  background-color:#dd600d
}
.dark-dark-orange{
  background-color: #3d1b04;
}
/* green-theme */
.green-theme{
  background-color: #369f3d;
}
.green-text{
color: #3dbd46;
}
.dark-green{
  background-color: #297b2e;
}
.light-green{
  background-color:#4ade54
}
.dark-dark-green{
  background-color: #0b370e;
}

/* just stuff */
.green-circle{
  width:0.8rem;
  height:0.8rem;
  background-color:rgb(0, 255, 0);
  border-radius: 1rem;
  margin-left:0.3rem
}

.greencircle-text{
  margin-top: 0 !important;
margin-bottom: 0 !important;
}
/* animation */
.pop-animation{
  animation-name: pop;
  animation-duration: 0.4s;
  animation-direction:normal;
  animation-timing-function:cubic-bezier(0.175, 0.885, 0.32, 1.275);
  
}
.pop-animation-1{
  animation-name: pop1;
  animation-duration: 0.4s;
  animation-direction:normal;
  animation-timing-function:cubic-bezier(0.175, 0.885, 0.32, 1.275);
  
}
.pop-animation-4{
  animation-name: pop4;
  animation-duration: 0.4s;
  animation-direction:normal;
  animation-timing-function:cubic-bezier(0.175, 0.885, 0.32, 1.275);
  
}
.pop-animation-3{
  animation-name: pop;
  animation-duration: 1s;
  animation-direction:normal;
  animation-timing-function:cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-iteration-count: 3;
}

.pop-in-top{
  animation-name: popintop;
  animation-duration: 0.4s;
  animation-direction:normal;
  animation-timing-function:cubic-bezier(0.175, 0.885, 0.32, 1.275);
  
}
.pop-in-bottom{
  animation-name: popinbottom;
  animation-duration: 0.4s;
  animation-direction:normal;
  animation-timing-function:cubic-bezier(0.175, 0.885, 0.32, 1.275);
  
}

@media screen and (max-width:850px) {
  .App{
    flex-direction: column;
  
    
  }
  .input-comp{
    padding-left:0.5rem
  }
  .game-view{
    width:100vw;
    height:100vh;
  }
  .background-view{
    width:180%;
  }
  .radial-view{
    height: 100vh;
  }
  .side-bar{
    position: absolute;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    padding:0.5rem;
    width:calc(100vw - 1rem);
    display: none;
    bottom:-100px;
    transition: 0.5s;
    z-index: 3;
    height: 75vh;
    bottom:-100vh;
    transition: 0.5s;
    box-shadow: 0 -1px 10px rgba(0,0,0,0.3);
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  .undo-display{
    display: flex;
  }
  .share-button{
    width: 100%;
    font-size: 1rem;
    height: 100%;
  }
  .win-text{
    font-size: 18vw !important;
  }
  .side-bar-active{
    display: flex;
    flex-direction: column;
    bottom:0;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: 0.5s;
  }
  .choice-comp{
    width: 80%;
    min-width: unset;
    max-width: 30rem;
  }
  .vs-text{
    font-size: 13vh;
  }
  .input-holder{
    width: 80%;
  }
  .sidebar-activator{
    display: flex;
    width: 100vw;
    height: 8vh;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-color: transparent;
    cursor: pointer;
  }
  .sidebar-activator:active{
    opacity: 0.7;
  }
  .top-sidebar{
    height:90%;
    overflow-y: scroll;
  
  }
  .bottom-sidebar{
    height:10%;
  }
  .win-row-comp{
    top:-49%;
    height:36%;
  }
  .confetti-img{
    transform: scale(1.3);
  }
  .win-button{
    width:14%;
  }
}